import { useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

const DailyRequest = () => {
  const [cookies, setCookie] = useCookies(["dailyRequest"]);

  const makeRequest = () => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "addtovisitscookies")
      .then((resp) => {
        console.log(resp.data);
        // Ustaw cookie, które wygaśnie o północy
        const now = new Date();
        const midnight = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1,
          0,
          0,
          0,
        );
        const expires = midnight;
        setCookie("dailyRequest", "true", { expires });
      })
      .catch((error) => {
        console.error("Error making request", error);
      });
  };

  const checkAndMakeRequest = () => {
    if (!cookies.dailyRequest) {
      makeRequest();
    } else {
      console.log("Request already made today");
    }
  };

  useEffect(() => {
    checkAndMakeRequest();
  }, []); // Pusty array jako drugi argument sprawia, że efekt uruchomi się tylko raz

  return null; // Ten komponent nie renderuje nic na UI
};

export default DailyRequest;
