import { useSelector } from "react-redux";

const useData = (param) => {
  const templateSettings = useSelector(
    (state) => state.websiteDates.templateSettings,
  );

  if (templateSettings.find((setting) => setting.name === param)) {
    return templateSettings.find((setting) => setting.name === param).value;
  }
  return "--";
};

export default useData;
