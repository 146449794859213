import style from "./ComponentForBackgroundImage.module.css";
import ComponentMainButton from "../../../../components/ComponentMainButton/ComponentMainButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useResize } from "../../../../pipes/use-resize";

const ComponentForBackgroundImage = () => {
  const navigate = useNavigate();

  function displaySubpageAbout() {
    navigate("/aboutUs");
  }

  const { width } = useResize();
  let data = useSelector((state) => state.websiteDates?.websiteDates);
  let templateSettings = useSelector(
    (state) => state.websiteDates?.templateSettings,
  );
  if (
    !data.length ||
    data.length < 3 ||
    !templateSettings ||
    templateSettings.length < 4
  ) {
    return <h2></h2>;
  }
  let shortDescription = templateSettings.find(
    (k) => k.name === "headPage_title",
  ).value;
  let history = templateSettings.find((k) => k.id == 215).value;

  function displaySubpageDonat() {
    navigate("/subpage/" + history);
  }

  const bannerId = templateSettings.find(
    (k) => k.name == "HeadPage_karuzela_image",
  ).value;
  return (
    <div
      className={style.mainContainer}
      style={{
        backgroundImage: `url(${process.env.REACT_APP_LINKTOAPI2}/getImage/${bannerId})`,
      }}
    >
      <div className={style.container}>
        <h2>Biblioteka</h2>
        <h1>{shortDescription}</h1>
        <div className={style.blockForBtns}>
          <ComponentMainButton
            styleClass={style.btn}
            content="Dowiedz się więcej"
            color="#FAC000"
            clickEvent={displaySubpageAbout}
            width={width > 800 ? "260px" : "180px"}
          />
          <ComponentMainButton
            styleClass={style.btn}
            content="Historia"
            color="#1265A8"
            clickEvent={displaySubpageDonat}
            width={width > 800 ? "260px" : "180px"}
          />
        </div>
      </div>
    </div>
  );
};
export default ComponentForBackgroundImage;
