import { useEffect } from "react";
import axios from "axios";

const ImageScanner = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const images = document.getElementsByTagName("img");
      Array.from(images).forEach((image) => {
        if (image.alt == "undefined") {
          image.alt = "Brak zdjęcia";
        }
        const altText = image.alt;
        const regex = /%_BigView_#%eFennec#%#%=(\d+)=%#eFennec%#%__#%/;
        const match = altText.match(regex);

        //  image.alt = "loading";

        if (match) {
          image.src =
            "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
          const imageId = match[1];
          console.log(imageId);
          //todo tymczasowe
          image.src = process.env.REACT_APP_LINKTOAPI2 + "getImage/" + imageId;
          // image.src = tempImage;
          image.alt = "";
          axios
            .get(
              process.env.REACT_APP_LINKTOAPI2 +
                `returnImageInformation/${imageId}`,
            )
            .then((response) => {
              image.alt = response?.data?.data[0]?.alt;
              if (!image.alt) image.alt = "Brak opisu zdjęcia";
            })
            .catch((error) => {
              console.error("Error fetching image information:", error);
              if (!image.alt) image.alt = "Brak zdjęcia";
            });
        }
      });
      const links = document.getElementsByTagName("a");

      Array.from(links).forEach((link) => {
        // Wyrażenie regularne do wyciągnięcia ID z href (np. api%_57_%)
        const regex = /api%_(\d+)_%/;
        const match = link.href.match(regex);
        if (match) {
          const linkId = match[1]; // Wyciągnięte ID z linku
          link.href = process.env.REACT_APP_LINKTOAPI2 + `getFile/${linkId}`;
          console.log("Zaktualizowany link:", link.href);
        }
      });
    }, 400);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return null; // Nie renderuj niczego
};

export default ImageScanner;
