import style from "./Map.module.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";

const Map = () => {
  const data = useSelector((state) => state.websiteDates.websiteDates);
  if (!data || data.length < 3) {
    return <h4>Loading....</h4>;
  }
  let latidute = data.find((k) => k.name == "latidute").value;
  let longitude = data.find((k) => k.name == "longitude").value;

  return (
    <div className={style.container}>
      <h2>Nasz adres:</h2>
      <MapContainer
        className={style.map}
        center={[latidute, longitude]}
        zoom={15}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[latidute, longitude]}>
          <Popup>Biblioteka</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};
export default Map;
