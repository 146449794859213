import style from "./Nav.module.css";
import { Turn as Hamburger } from "hamburger-react";
import { useResize } from "../../../../../pipes/use-resize";
import NavOptions from "../NavOptions/NavOptions";

const Nav = (props) => {
  const { width } = useResize();
  return (
    <>
      <div className={style.container} ref={props.navRedf}>
        {/*<NavOptions isOpen={!props.isOpen} />*/}

        {width > 1400 && (
          <NavOptions isOpen={!props.isOpen} setOpen={props.setOpen} />
        )}
      </div>
      <div className={style.hamburger}>
        <Hamburger size={35} toggled={props.isOpen} toggle={props.setOpen} />
      </div>
    </>
  );
};
export default Nav;
