import style from "./Links.module.css";
import { useNavigate } from "react-router-dom";
import Link from "./Link/Link";
import { useEffect, useState } from "react";
import axios from "axios";

const Links = () => {
  const [links, setLinks] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "template_settings/template_id/5/")
      .then((resp) => {
        let array = [];
        for (let k = 1; k <= 15; k++) {
          let obj = {
            href: resp.data.data.find(
              (link) => link.name.toString() === "links_" + k + "_href",
            ).value,
            status: resp.data.data.find(
              (link) => link.name.toString() === "links_" + k + "_status",
            ).value,
            image_id: resp.data.data.find(
              (link) => link.name.toString() === "links_" + k + "_image_id",
            ).value,
            title: resp.data.data.find(
              (link) => link.name.toString() === "links_" + k + "_title",
            ).value,
          };
          array.push(obj);
        }
        setLinks(array);
      })
      //setLinks(resp.data.data);
      .catch((error) => {
        navigate("/error");
      });
  }, []);
  const linksMap = links
    .sort((k1, k2) => k1.position > k2.position)
    .filter((link) => link.status == "true")
    .map((link, index) => <Link key={index} link={link} />);
  if (links.length < 1) return "";

  return (
    <div className={style.container} id="links">
      <h2>Główne linki:</h2>
      <p>
        Poniżej znajdują się główne linki, które kierują do kluczowych zasobów
        oraz istotnych informacji. Zapraszamy do korzystania z nich w celu
        uzyskania potrzebnych informacji.
      </p>

      <div className={style.containerForLinks}>{linksMap}</div>
    </div>
  );
};
export default Links;
