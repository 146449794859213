import style from "./Content.module.css";
import ComponentMainButton from "../../../../../components/ComponentMainButton/ComponentMainButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";

const Content = (props) => {
  const navigate = useNavigate();
  const data = props.data;

  function displaySubPage() {
    navigate("/aboutUs");
  }

  const templateSettings = useSelector(
    (state) => state.websiteDates.templateSettings,
  );

  const [text, setText] = useState("");
  useEffect(() => {
    if (templateSettings.length > 0) {
      const id = templateSettings.find((k) => k.id == 216).value;
      axios(process.env.REACT_APP_LINKTOAPI2 + "/pre_subPage/" + id)
        .then((resp) => {
          setText(resp.data.data[0].body);
        })
        .catch((error) => {
          //        navigate("/error");
        });
    }
  }, [templateSettings]);
  if (templateSettings.length < 4) return "";

  return (
    <div className={style.container}>
      {/*<h2>{data.titleOfHeading}</h2>*/}
      <h2>O nas:</h2>
      <p dangerouslySetInnerHTML={{ __html: text.split("<hr")[0] }}></p>
      {/*<div className={style.image}>*/}
      {/*  <img*/}
      {/*    alt={*/}
      {/*      "%_BigView_#%eFennec#%#%=" +*/}
      {/*      templateSettings.find((k) => k.id == 222).value +*/}
      {/*      "=%#eFennec%#%__#%"*/}
      {/*    }*/}
      {/*  />*/}
      {/*  /!*<img src={"https://placehold.co/700x228/EEE/31343C"} />*!/*/}
      {/*</div>*/}
      <ComponentMainButton
        content="Więcej"
        color="#FFD801"
        clickEvent={displaySubPage}
      />
    </div>
  );
};
export default Content;
