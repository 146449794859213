import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DefaultPanel from "./defaultPanel/defaultPanel";
import { Provider } from "react-redux";
import store from "./data/store";
import Default from "./defaultPanel/dynamicSubPages/default/Default";
import AboutUsFully from "./defaultPanel/dynamicSubPages/AboutUsFully/AboutUsFully";
import ChoosedAspect from "./defaultPanel/dynamicSubPages/ChoosedAspect/ChoosedAspect";
import AspectsOfTheProject from "./defaultPanel/dynamicSubPages/default/AspectsOfTheProject/AspectsOfTheProject";
import OurProjects from "./defaultPanel/dynamicSubPages/default/OurProjects/OurProjects";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Donat from "./defaultPanel/dynamicSubPages/Donat/Donat";
import ErrorComponent from "./components/ErrorComponent/ErrorComponent";
import ChoosedProject from "./defaultPanel/dynamicSubPages/ChoosedProject/ChoosedProject";
import "animate.css/animate.min.css";
import Error404Component from "./components/Error404Component/Error404Component";
import Subpage from "./defaultPanel/dynamicSubPages/Subpage/Subpage";
import ChoosedArticle from "./defaultPanel/dynamicSubPages/ChoosedArticle/ChoosedArticle";
import ImageScanner from "./components/ImageScaner";
import PageMap from "./defaultPanel/dynamicSubPages/PageMap/PageMap";
import Gallery from "./defaultPanel/dynamicSubPages/Gallery/Gallery";
import GalleryYear from "./defaultPanel/dynamicSubPages/Gallery/GalleryYear/GalleryYear";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <DefaultPanel />,
        children: [
          {
            path: "/",
            element: <Default />,
          },
          {
            path: "/subpage/:id",
            element: <Subpage />,
          },
          {
            path: "/gallery",
            element: <Gallery />,
          },
          {
            path: "/gallery/:id",
            element: <GalleryYear />,
          },
          {
            path: "/article/:id",
            element: <ChoosedArticle />,
          },
          {
            path: "/aboutUs",
            element: <AboutUsFully />,
          },
          {
            path: "/aspects",
            element: <AspectsOfTheProject allAspects={true} />,
          },
          {
            path: "/projects",
            element: <OurProjects allProjects={true} />,
          },
          {
            path: "/aspect/:id",
            element: <ChoosedAspect />,
          },
          {
            path: "/project/:id",
            element: <ChoosedProject />,
          },
          {
            path: "/donat",
            element: <Donat />,
          },
          {
            path: "/pageMap",
            element: <PageMap />,
          },
        ],
      },
      {
        path: "*",
        element: <Error404Component />,
      },
      {
        path: "/error",
        element: <ErrorComponent />,
      },
    ],
    { basename: "" },
  );
  return (
    <div className="App">
      <Provider store={store}>
        <ImageScanner />
        <RouterProvider router={router} />
        <ToastContainer />
      </Provider>
    </div>
  );
}

export default App;
