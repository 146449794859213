import style from "./ChoosedArticle.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import imageOfArticle from "../../../assets/images/bookIcon.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faHeart } from "@fortawesome/free-solid-svg-icons";
import { fixEncoding } from "../../../pipes/fixEncoding";
import ArrowBack from "../../../components/ArrowBack/ArrowBack";

const ChoosedArticle = () => {
  const { id } = useParams();
  const [data, setData] = useState(undefined);
  const [like, setLike] = useState(false);
  const [likeTemp, setLikeTemp] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      if (data.selectLike > 0) {
        setLike(true);
      }
    }
  }, [data]);

  function setLikeFunc() {
    if (!like) {
      axios(process.env.REACT_APP_LINKTOAPI2 + "pre_addLike/" + data.id)
        .then((resp) => {})
        .catch((error) => {});
      if (data.selectLike == 1) {
        setLikeTemp(0);
      } else {
        setLikeTemp(1);
      }
    } else {
      axios(process.env.REACT_APP_LINKTOAPI2 + "pre_removeLike/" + data.id)
        .then((resp) => {})
        .catch((error) => {});
      if (data.selectLike == 1) {
        setLikeTemp(-1);
      } else {
        setLikeTemp(0);
      }
    }

    setLike(!like);
  }

  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "/addVisitToArticle/" + id)
      .then((resp) => {})
      .catch((error) => {
        //        navigate("/error");
      });
    axios(process.env.REACT_APP_LINKTOAPI2 + "/pre_article/" + id)
      .then((resp) => {
        setData(resp.data.data[0]);
      })
      .catch((error) => {
        //        navigate("/error");
      });
  }, []);

  if (!data) return "";

  return (
    <div className={style.container}>
      <div className={style.content}>
        <ArrowBack onClickEvent={() => navigate(-1)} />
        <div className={style.header}>
          {data.headImage.length == 0 ? (
            <div className={style.noImage}>
              <FontAwesomeIcon icon={faCamera} />
            </div>
          ) : (
            <img
              className={style.headImage}
              src={imageOfArticle}
              alt={
                "%_BigView_#%eFennec#%#%=" +
                data.headImage +
                "=%#eFennec%#%__#%"
              }
            />
          )}
          <div className={style.titleContainer}>
            <h2>{fixEncoding(data.title)}</h2>
            <div className={style.blockForInformation}>
              <div className={style.blockForSpan}>
                <span className={style.spanCategoryAndWhenPublished}>
                  Kategoria:
                </span>
                <span className={style.mainInfoSpan}>
                  {fixEncoding(data.category)}
                </span>
              </div>
              <div className={style.blockForSpan}>
                <span className={style.spanCategoryAndWhenPublished}>
                  Opublikowano:
                </span>
                <span className={style.mainInfoSpan}>
                  {data?.publish_date?.split(" ")[0]}
                </span>
              </div>
              <div className={style.like}>
                <FontAwesomeIcon
                  icon={faHeart}
                  fontSize="20px"
                  onClick={setLikeFunc}
                  className={`${style.likeIcon} ${like ? style.redColor : ""}`}
                ></FontAwesomeIcon>{" "}
                <span>{+data.likes + +likeTemp}</span>
              </div>
            </div>
          </div>
        </div>

        <p dangerouslySetInnerHTML={{ __html: fixEncoding(data.body) }}></p>
      </div>
    </div>
  );
};

export default ChoosedArticle;
