import style from "./Subpage.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ArrowBack from "../../../components/ArrowBack/ArrowBack";
import { fixEncoding } from "../../../pipes/fixEncoding";

const Subpage = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "subpages/id/" + id)
      .then((resp) => {
        setData(resp.data.data[0]);
      })
      .catch((error) => {
        //        navigate("/error");
      });
  }, [id]);

  if (!data) return "";
  return (
    <div className={style.container}>
      <ArrowBack onClickEvent={() => window.history.back()} />
      <div className={style.content}>
        <h2>{fixEncoding(data.title)}</h2>
        <p dangerouslySetInnerHTML={{ __html: fixEncoding(data.body) }}></p>
      </div>
    </div>
  );
};
export default Subpage;
