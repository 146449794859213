import style from "./Articles.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import CardOfArticle from "./CardOfArticle/CardOfArticle";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [articlesSize, setArticlesSize] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "pre_articleList/" + currentPage)
      .then((resp) => {
        setArticles(resp.data.data.article);
        setArticlesSize(resp.data.data.size);
      })
      .catch((error) => {
        navigate("/error");
      });
  }, [currentPage]);

  const articlesList = articles
    //    .slice((currentPage - 1) * 4, (currentPage - 1) * 4 + 4)
    .map((article, index) => <CardOfArticle key={index} article={article} />);

  return (
    <div className={style.mainContainer} id="articles">
      <h2>Nasze artykuły:</h2>
      <div className={style.container}>{articlesList}</div>
      <ResponsivePagination
        current={currentPage}
        maxWidth={"500px"}
        total={Math.ceil(articlesSize / 4)}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};
export default Articles;
