import style from "./Gallery.module.css";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBack from "../../../components/ArrowBack/ArrowBack";
import { fixEncoding } from "../../../pipes/fixEncoding";

const Gallery = () => {
  const [data, setData] = useState([]);
  const dataMempo = useMemo(() => groupByYear(data), [data]);

  function groupByYear(events) {
    const grouped = [];
    const years = {};
    events.forEach((event) => {
      const year = event.year;
      if (!years[year]) {
        years[year] = grouped.length;
        grouped.push([]);
      }
      const index = years[year];
      grouped[index].push(event);
    });
    return grouped;
  }

  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "/gallery_pages")
      .then((resp) => {
        setData(groupByYear(resp.data.data));
      })
      .catch((error) => {});
  }, []);
  const navigate = useNavigate();
  return (
    <div className={style.container}>
      <ArrowBack onClickEvent={() => navigate("/")} />
      <div className={style.wrapper}>
        {dataMempo.map((year, index1) => {
          return (
            <div className={style.year} key={index1}>
              {year.map((gallery, index2) => {
                return (
                  <div key={index2} className={style.gallery}>
                    <h3>{gallery.title}</h3>
                    <p key={index2}>
                      <br />
                      <h3>
                        {gallery[0].year} / {+gallery[0].year + 1}
                      </h3>
                      {gallery.map((k, index3) => {
                        return (
                          <>
                            <NavLink key={index3} to={"/gallery/" + k.id}>
                              {fixEncoding(k.title)}
                            </NavLink>
                            <br />
                          </>
                        );
                      })}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}{" "}
      </div>
    </div>
  );
};
export default Gallery;
// <img
//   key={index3}
//   alt={
//     "%_BigView_#%eFennec#%#%=" + k + "=%#eFennec%#%__#%"
//   }
// />
// );
