import { createSlice } from "@reduxjs/toolkit";

const websiteDataReducer = createSlice({
  name: "websiteDates",
  initialState: {
    websiteDates: [],
    templateSettings: [],
  },
  reducers: {
    setWebsiteData(state, action) {
      return { ...state, websiteDates: action.payload };
    },
    setTemplateSettings(state, action) {
      return { ...state, templateSettings: action.payload };
    },
  },
});
export const { setWebsiteData, setTemplateSettings } =
  websiteDataReducer.actions;
export default websiteDataReducer.reducer;
