import style from "./Link.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Link = ({ link }) => {
  return (
    <a
      href={link?.href}
      aria-label={"Link prowadzący do " + link?.href}
      className={style.link}
    >
      <div className={style.container}>
        <span>{link?.title}</span>
        <FontAwesomeIcon icon={faArrowDown} className={style.icon} />
      </div>
    </a>
  );
};
export default Link;
