import style from "./PageMap.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { fixEncoding } from "../../../pipes/fixEncoding";

const PageMap = () => {
  const [article, setArticle] = useState(null);
  const [subPages, setSubPages] = useState(null);
  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "articles/status/published")
      .then((resp) => {
        setArticle(resp.data.data);
        console.log(resp.data.data);
      })
      .catch((error) => {});
    axios(process.env.REACT_APP_LINKTOAPI2 + "subpages/status/published")
      .then((resp) => {
        setSubPages(resp.data.data);
      })
      .catch((error) => {});
  }, []);

  if (!article || !subPages) {
    return "loading";
  }
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <h2>Mapa strony</h2>
        <ul>
          <li>
            Artykuły
            <ul>
              {article.map((k) => {
                return (
                  <li>
                    <NavLink to={"/article/" + k.id}>
                      {fixEncoding(k.title)}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </li>
          <li>
            Podstrony
            <ul>
              {subPages.map((k) => {
                return (
                  <li>
                    <NavLink to={"/subpage/" + k.id}>{k.title}</NavLink>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default PageMap;
