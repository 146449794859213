import style from "./Accessibility.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import CursorLine from "./CursorLine/CursorLine";

const Accessibility = () => {
  const [open, setOpen] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);
  const [fontSize, setFontSize] = useState(16); // Domyślny rozmiar czcionki
  const [showLine, setShowLine] = useState(false);
  const [grayscale, setGrayscale] = useState(false);
  const [invertColors, setInvertColors] = useState(false);
  const [lineHeight, setLineHeight] = useState(1); // Domyślna wysokość linii
  const [lowSaturation, setLowSaturation] = useState(false);
  const [highSaturation, setHighSaturation] = useState(false);

  function stop(k) {
    k.stopPropagation();
  }

  const toggleUnderline = () => {
    setIsUnderlined(!isUnderlined);
  };

  const underlineLinks = () => {
    const allLinks = document.querySelectorAll("a");
    allLinks.forEach((link) => {
      link.style.textDecoration = isUnderlined ? "underline" : "none";
    });
  };

  useEffect(() => {
    // Podkreślenie linków po załadowaniu komponentu i zmianie stanu
    underlineLinks();
    // Ustawienie MutationObserver do obserwacji zmian w DOM
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          // Podkreślenie linków po dodaniu nowych elementów
          underlineLinks();
        }
      });
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    // Czyszczenie obserwatora przy odmontowywaniu komponentu
    return () => observer.disconnect();
  }, [isUnderlined]);

  const increaseFontSize = () => {
    if (fontSize < 30) {
      const newFontSize = Math.min(fontSize + 2, 30); // Zwiększ o 2px, maksymalnie do 30px
      document.documentElement.style.fontSize = `${newFontSize}px`;
      setFontSize(newFontSize);
    }
  };

  const decreaseFontSize = () => {
    if (fontSize > 12) {
      const newFontSize = Math.max(fontSize - 2, 12); // Zmniejsz o 2px, minimalnie do 12px
      document.documentElement.style.fontSize = `${newFontSize}px`;
      setFontSize(newFontSize);
    }
  };

  const resetOptions = () => {
    setIsUnderlined(false);
    setFontSize(16);
    setShowLine(false);
    setGrayscale(false);
    setInvertColors(false);
    setLowSaturation(false);
    setHighSaturation(false);
    setLineHeight(1);
    const allElements = document.querySelectorAll("*");
    allElements.forEach((element) => {
      element.style.textDecoration = "none";
    });
    document.documentElement.style.filter = "none";

    document.documentElement.style.fontSize = "16px";
  };
  const toggleGrayscale = () => {
    setGrayscale(!grayscale);
    document.documentElement.style.filter = grayscale
      ? "none"
      : "grayscale(100%)";
  };

  const toggleInvertColors = () => {
    setInvertColors(!invertColors);
    document.documentElement.style.filter = invertColors
      ? "none"
      : "invert(100%)";
  };

  const toggleLowSaturation = () => {
    setLowSaturation(!lowSaturation);
    document.documentElement.style.filter = lowSaturation
      ? "none"
      : "saturate(20%)";
  };

  const toggleHighSaturation = () => {
    setHighSaturation(!highSaturation);
    document.documentElement.style.filter = highSaturation
      ? "none"
      : "saturate(200%)";
  };

  const increaseLineHeight = () => {
    const newLineHeight = Math.min(lineHeight + 0.1, 2); // Zwiększ o 0.1, maksymalnie do 2
    setLineHeight(newLineHeight);
    document.documentElement.style.lineHeight = newLineHeight;
  };

  const decreaseLineHeight = () => {
    const newLineHeight = Math.max(lineHeight - 0.1, 0.5); // Zmniejsz o 0.1, minimalnie do 0.5
    setLineHeight(newLineHeight);
    document.documentElement.style.lineHeight = newLineHeight;
  };

  return (
    <>
      <div
        className={style.container}
        onClick={() => setOpen(!open)}
        aria-label={"Menu z opcjami dla niepełnosprawnych"}
      >
        <FontAwesomeIcon icon={faWheelchair} className={style.icon} />
        <div
          onClick={stop}
          className={`${style.block} ${open ? style.open : ""}`}
        >
          <button onClick={increaseFontSize} tabIndex={1}>
            Zwiększ czcionkę
          </button>
          <button onClick={decreaseFontSize} tabIndex={2}>
            Zmniejsz czcionkę
          </button>
          <button onClick={toggleUnderline} tabIndex={3}>
            Podkreśl link
          </button>

          <CursorLine showLine={showLine} setShowLine={setShowLine} />
          <button onClick={toggleGrayscale} tabIndex={5}>
            Odcienie szarości
          </button>
          <button onClick={toggleInvertColors} tabIndex={6}>
            Odwróć kolory
          </button>
          <button onClick={toggleLowSaturation} tabIndex={7}>
            Niska saturacja
          </button>
          <button onClick={toggleHighSaturation} tabIndex={8}>
            Wysoka saturacja
          </button>
          <button onClick={increaseLineHeight} tabIndex={9}>
            Zwiększ wysokość linii
          </button>
          <button onClick={decreaseLineHeight} tabIndex={10}>
            Zmniejsz wysokość linii
          </button>
          <button onClick={resetOptions} tabIndex={11}>
            Resetuj opcje
          </button>
        </div>
      </div>
    </>
  );
};
export default Accessibility;
