import style from "./ContentAboutUsFully.module.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

const ContentAboutUsFully = () => {
  // const data = useSelector(
  //   (state) => state.websiteDates?.websiteDates?.aboutUs,
  // );
  // if (!data) {
  //   return <h2>Loading....</h2>;
  // }
  const templateSettings = useSelector(
    (state) => state.websiteDates.templateSettings,
  );

  const [text, setText] = useState("");
  const [history, setHistory] = useState("");
  const [status, setStatut] = useState("");
  useEffect(() => {
    if (templateSettings.length > 0) {
      setHistory(templateSettings.find((k) => k.id == 215).value);
      setStatut(templateSettings.find((k) => k.id == 217).value);
      let id = templateSettings.find((k) => k.id == 216).value;
      axios(process.env.REACT_APP_LINKTOAPI2 + "/pre_subPage/" + id)
        .then((resp) => {
          setText(resp.data.data[0].body);
        })
        .catch((error) => {
          //        navigate("/error");
        });
    }
  }, [templateSettings]);
  return (
    <div className={style.container}>
      <h2>O nas</h2>
      <p dangerouslySetInnerHTML={{ __html: text.split("<hr />")[1] }}></p>

      <div className={style.blockForBtns}>
        <NavLink to={"/subpage/" + history}>
          <button>Zobacz naszą historię</button>
        </NavLink>
        <NavLink to={"/gallery"}>
          <button>Zobacz naszą galerię</button>
        </NavLink>
        <NavLink to={"/subpage/" + status}>
          <button>Statut</button>
        </NavLink>
      </div>
    </div>
  );
};
export default ContentAboutUsFully;
