import style from "./Header.module.css";
import ComponentForBackgroundImage from "./ComponentForBackgroundImage/ComponentForBackgroundImage";

const Header = () => {
  return (
    <div className={style.container} id="top">
      <ComponentForBackgroundImage />
    </div>
  );
}; //
export default Header;
