import style from "./Error404Component.module.css";
import errorImg from "../../assets/images/errorUA.jpg";
import { useNavigate } from "react-router-dom";

const Error404Component = () => {
  const navigate = useNavigate();

  function displayMainPage() {
    navigate("/");
  }

  return (
    <div className={style.container}>
      <img src={errorImg} alt="error 404" />
      <span>Strona nie znaleziona</span>
      <button onClick={displayMainPage}>Strona główna</button>
    </div>
  );
};
export default Error404Component;
