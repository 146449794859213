import style from "./MenuLink.module.css";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import toScroll from "../../../../../../pipes/toScroll";

const MenuLink = (props) => {
  const value = props.value;
  const subMenu = props.subMenu;
  const isOpen = false;
  var to = "./#body";
  if (value.mode == "subPage") {
    to = "/subpage/" + value.menuModeValue;
  }
  if (value.mode == "href") {
    to = value.menuModeValue;
  }

  if (value.mode == "article") {
    to = "/article/" + value.menuModeValue;
  }
  if (value.mode == "pageMap") {
    to = "/pageMap";
  }
  if (value.mode == "galleryList") {
    to = "/gallery";
  }

  function click() {
    props.close ? props.close() : console.log("No props close in menuLink");
    if (subMenu.length === 0) {
    } else {
      console.log(props);
    }
  }

  const sub = (subMenuLoc) => {
    return (
      <div
        className={style.subMenu}
        onClick={() => {
          clickElement();
        }}
      >
        <div>
          {subMenuLoc.map((k, index) => {
            return (
              <>
                {/*{k.mode}*/}
                {k.mode === "href" && (
                  <HashLink
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    scroll={(el) => toScroll(el)}
                    to={k.menuModeValue}
                    tabIndex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </HashLink>
                )}
                {k.mode === "galleryList" && (
                  <NavLink
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    to={"/gallery"}
                    tabIdex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </NavLink>
                )}
                {k.mode === "subPage" && (
                  <NavLink
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    to={"subPage/" + k.menuModeValue}
                    tabIdex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </NavLink>
                )}
                {k.mode === "article" && (
                  <NavLink
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    to={"article/" + k.menuModeValue}
                    tabIdex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </NavLink>
                )}
                {k.mode === "default" && (
                  <NavLink
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    to={k.menuModeValue}
                    tabIdex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </NavLink>
                )}
                {k.mode === "pageMap" && (
                  <NavLink
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    to={"/pageMap"}
                    tabIdex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </NavLink>
                )}
                {k.mode === "download" && (
                  <a
                    onClick={() => {
                      props.setOpen(false);
                      clickElement();
                    }}
                    href={
                      process.env.REACT_APP_LINKTOAPI2 +
                      "getFile/" +
                      k.menuModeValue
                    }
                    target={"_blank"}
                    tabIdex={isOpen ? 10 + 100 * props.index + 2 + index : 0}
                  >
                    {k.name}
                  </a>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const [click2, setClick2] = useState(0);

  function clickElement() {
    setClick2(1);
    setTimeout(() => {
      setClick2(0);
    }, 0);
  }

  return (
    <>
      {subMenu.length === 0 && (
        <HashLink
          className={style.link}
          onClick={() => {
            props.setOpen(false);
            clickElement();
          }}
          to={to}
          scroll={(el) => toScroll(el)}
          tabIndex={isOpen ? 10 + 100 * props.index + 1 : 0}
        >
          {value.name}
          {subMenu.length > 0 && sub(subMenu)}
        </HashLink>
      )}
      {subMenu.length !== 0 && (
        <span
          className={`${style.link}  hidden-submenu `}
          to={to}
          onClick={() => {
            if (subMenu.length === 0) {
              console.log(subMenu.length);
              clickElement();
            }
          }}
        >
          {value.name}
          {subMenu.length > 0 && click2 == 0 && sub(subMenu)}
          <b className={style.arrow}>> </b>
        </span>
      )}
    </>
  );
};
export default MenuLink;
