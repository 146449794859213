import style from "./CardOfArticle.module.css";
import { maxLenght } from "../../../../../pipes/maxLenght";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import axios from "axios";
import imageOfArticle from "../../../../../assets/images/bookIcon.png";
import { fixEncoding } from "../../../../../pipes/fixEncoding";

const CardOfArticle = (props) => {
  const [like, setLike] = useState(false);
  const [likeTemp, setLikeTemp] = useState(0);
  const data = props.article;
  useEffect(() => {
    if (data.selectLike > 0) {
      setLike(true);
    }
  }, []);

  function setLikeFunc() {
    if (!like) {
      axios(process.env.REACT_APP_LINKTOAPI2 + "pre_addLike/" + data.id)
        .then((resp) => {})
        .catch((error) => {});
      if (data.selectLike == 1) {
        setLikeTemp(0);
      } else {
        setLikeTemp(1);
      }
    } else {
      axios(process.env.REACT_APP_LINKTOAPI2 + "pre_removeLike/" + data.id)
        .then((resp) => {})
        .catch((error) => {});
      if (data.selectLike == 1) {
        setLikeTemp(-1);
      } else {
        setLikeTemp(0);
      }
    }

    setLike(!like);
  }

  return (
    <div className={style.container}>
      <div className={style.blockForImgAndSomeInfo}>
        {data.headImage.length == 0 ? (
          // <div className={style.noImage}>
          //   <FontAwesomeIcon icon={faCamera} />
          // </div>
          <img src={imageOfArticle} alt={"Ikona książki"} />
        ) : (
          <img
            src={imageOfArticle}
            alt={
              "%_BigView_#%eFennec#%#%=" + data.headImage + "=%#eFennec%#%__#%"
            }
          />
        )}
        <div className={style.blockForSpans}>
          <div className={style.blockForSpan}>
            <span className={style.spanCategoryAndWhenPublished}>
              Kategoria:
            </span>
            <span className={style.mainInfoSpan}>
              {fixEncoding(data.category)}
            </span>
          </div>
          <div className={style.blockForSpan}>
            <span className={style.spanCategoryAndWhenPublished}>
              Opublikowano:
            </span>
            <span className={style.mainInfoSpan}>
              {data.publish_date.split(" ")[0]}
            </span>
          </div>
        </div>
      </div>

      <div className={style.containerForDescription}>
        <NavLink
          to={"/article/" + data.id}
          aria-label={"Link do artykułu: " + data.title}
        >
          <h3 className="titleOfArticle">{fixEncoding(data.title)}</h3>
        </NavLink>
        <p
          className="contentInArticle"
          style={{ flexGrow: 5000 }}
          dangerouslySetInnerHTML={{
            __html: maxLenght(fixEncoding(data.body), 525),
          }}
        ></p>
        <div className={style.blockForOtherInfo}>
          <div className={style.btnReadMore}>
            <NavLink to={"/article/" + data.id}>Czytaj&nbsp;więcej</NavLink>
          </div>
          <div className={style.like}>
            <FontAwesomeIcon
              icon={faHeart}
              fontSize="20px"
              onClick={setLikeFunc}
              className={`${style.likeIcon} ${like ? style.redColor : ""}`}
            ></FontAwesomeIcon>{" "}
            <span>{+data.likes + +likeTemp}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardOfArticle;
