import style from "./OpenHours.module.css";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const OpenHours = () => {
  const data = useSelector((state) => state.websiteDates.templateSettings);
  if (!data || data.length < 1) {
    return <h4>Loading....</h4>;
  }

  const monday = {
    open: data.find((k) => k.name == "workTime_Pon_p").value,
    close: data.find((k) => k.name == "workTime_Pon_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Pon_k").value !== "00:00";
    },
  };
  const tuesday = {
    open: data.find((k) => k.name == "workTime_Wt_p").value,
    close: data.find((k) => k.name == "workTime_Wt_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Wt_k").value !== "00:00";
    },
  };
  const wednesday = {
    open: data.find((k) => k.name == "workTime_Sr_p").value,
    close: data.find((k) => k.name == "workTime_Sr_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Sr_k").value !== "00:00";
    },
  };
  const thursday = {
    open: data.find((k) => k.name == "workTime_Czw_p").value,
    close: data.find((k) => k.name == "workTime_Czw_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Czw_k").value !== "00:00";
    },
  };
  const friday = {
    open: data.find((k) => k.name == "workTime_Pt_p").value,
    close: data.find((k) => k.name == "workTime_Pt_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Pt_k").value !== "00:00";
    },
  };
  const saturday = {
    open: data.find((k) => k.name == "workTime_Sob_p").value,
    close: data.find((k) => k.name == "workTime_Sob_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Sob_k").value !== "00:00";
    },
  };
  const sunday = {
    open: data.find((k) => k.name == "workTime_Nie_p").value,
    close: data.find((k) => k.name == "workTime_Nie_k").value,
    isOpen: () => {
      return data.find((k) => k.name == "workTime_Nie_k").value !== "00:00";
    },
  };
  return (
    <div className={style.container}>
      <div className={style.blockForTitleAndIcon}>
        <h4>Godziny otwarcia:</h4>
        <FontAwesomeIcon icon={faClock} className={style.icon} />
      </div>
      <div className={style.blockForOpeningHours}>
        <span>
          Poniedziałek:{" "}
          {monday.isOpen() ? `${monday.open}-${monday.close}` : "nieczynne"}
        </span>
        <span>
          Wtorek:{" "}
          {tuesday.isOpen() ? `${tuesday.open}-${tuesday.close}` : "nieczynne"}
        </span>
        <span>
          Środa:{" "}
          {wednesday.isOpen()
            ? `${wednesday.open}-${wednesday.close}`
            : "nieczynne"}
        </span>
        <span>
          Czwartek:{" "}
          {thursday.isOpen()
            ? `${thursday.open}-${thursday.close}`
            : "nieczynne"}
        </span>
        <span>
          Piątek:{" "}
          {friday.isOpen() ? `${friday.open}-${friday.close}` : "nieczynne"}
        </span>
        <span>
          Sobota:{" "}
          {saturday.isOpen()
            ? `${saturday.open}-${saturday.close}`
            : "nieczynne"}
        </span>
        <span>
          Niedziela:{" "}
          {sunday.isOpen() ? `${sunday.open}-${sunday.close}` : "nieczynne"}
        </span>
      </div>
    </div>
  );
};
export default OpenHours;
