import style from "./BeltForContact.module.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookInBelt from "./../../../../assets/images/imgInBelt.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const BeltForContact = () => {
  const dataOfHeader = useSelector((state) => state.websiteDates.websiteDates);
  const templateSettings = useSelector(
    (state) => state.websiteDates.templateSettings,
  );

  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");
  useEffect(() => {
    if (dataOfHeader.length > 0 && templateSettings.length > 0) {
      setPhone(templateSettings.find((k) => k.id == 214).value);
      setText(templateSettings.find((k) => k.id == 197).value);
    }
  }, [dataOfHeader, templateSettings]);

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.blockForContact}>
          <FontAwesomeIcon icon={faPhone} className={style.iconPhone} />
          <a
            tabIndex={0}
            href={
              "tel:" +
              phone?.replace("+", "").replaceAll("  ", "").replaceAll(" ", "")
            }
            className={style.tegForContact}
          >
            {phone ? phone : ""}
          </a>
        </div>
        <div className={style.blockForQuote}>
          <img src={bookInBelt} alt="book" />
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
};
export default BeltForContact;
