import style from "./ComponentForLogoAndTitle.module.css";
import { useSelector } from "react-redux";
import imageOfArticle from "../../../../../assets/images/bookIcon.jpg";
import { HashLink } from "react-router-hash-link";
import toScroll from "../../../../../pipes/toScroll";

const ComponentForLogoAndTitle = (props) => {
  let title = useSelector((state) => state.websiteDates?.websiteDates);
  const templateSettings = useSelector(
    (state) => state.websiteDates.templateSettings,
  );
  if (!title.length || title.length < 3 || templateSettings.length < 3) {
    return <h2>Loading....</h2>;
  }
  title = title.find((k) => k.name === "schoolName").value;
  let logo = templateSettings.find((k) => k.name === "herbImage_id").value;

  return (
    <div className={`${style.container} ${props.belt ? "" : style.min}`}>
      <div className={style.content}>
        <HashLink to={"/#top"} tabIndex={0} scroll={(el) => toScroll(el)}>
          <h1>
            <img
              src={imageOfArticle}
              alt={"%_BigView_#%eFennec#%#%=" + logo + "=%#eFennec%#%__#%"}
            />

            <span dangerouslySetInnerHTML={{ __html: title }}></span>
          </h1>
        </HashLink>
      </div>
    </div>
  );
};
export default ComponentForLogoAndTitle;
