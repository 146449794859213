import style from "./DonationAndAdvice.module.css";
import ComponentMainButton from "../ComponentMainButton/ComponentMainButton";

const DonationAndAdvice = (props) => {
  const title = props.title;
  const whichFunction = props.onClickEvent;
  return (
    <a
      href={"#"}
      className={style.container}
      onClick={whichFunction}
      aria-label={props.ariaLabel}
    >
      <div className={style.content}>
        <h3>{title}</h3>
        <ComponentMainButton
          content="Dokładniej"
          color="#FFD801"
          clickEvent={whichFunction}
          styleClass={style.mainBtn}
        />
      </div>
    </a>
  );
};
export default DonationAndAdvice;
