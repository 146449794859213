import style from "./GalleryYear.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBack from "../../../../components/ArrowBack/ArrowBack";
import { fixEncoding } from "../../../../pipes/fixEncoding";
import "react-awesome-lightbox/build/style.css";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";

const GalleryYear = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [imageOpen, setImageOpen] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "/gallery_pages")
      .then((resp) => {
        setData(resp.data.data.find((k) => k.id == id));
      })
      .catch((error) => {});
  }, [id]);

  const images = data?.images?.split(",") || [];

  const handlePrevImage = () => {
    setImageOpen((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setImageOpen((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  return (
    <div className={style.container}>
      <ArrowBack onClickEvent={() => navigate("/gallery")} />
      <div className={style.wrapper}>
        <h2>{fixEncoding(data.title)}</h2>
        <div className={style.images}>
          {images.map((img, index) => (
            <button key={index} onClick={() => setImageOpen(index)}>
              <img
                aria-label={"Kliknij aby powiększyć"}
                src={
                  "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                }
                alt={"%_BigView_#%eFennec#%#%=" + img + "=%#eFennec%#%__#%"}
              />
            </button>
          ))}
        </div>
      </div>
      <Lightbox
        open={imageOpen != null}
        index={imageOpen}
        close={() => setImageOpen(null)}
        slides={images.map((img, index) => ({
          src: "/image1.jpg",
          alt: "%_BigView_#%eFennec#%#%=" + img + "=%#eFennec%#%__#%",
        }))}
      />
      {/*{imageOpen !== null && (*/}
      {/*  <div className={style.image}>*/}
      {/*    <button*/}
      {/*      aria-label={"Poprzednie zdjęcie"}*/}
      {/*      className={style.arrow}*/}
      {/*      onClick={handlePrevImage}*/}
      {/*    >*/}
      {/*      {"<"}*/}
      {/*    </button>*/}
      {/*    <img*/}
      {/*      src={"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="}*/}
      {/*      alt={*/}
      {/*        "%_BigView_#%eFennec#%#%=" +*/}
      {/*        images[imageOpen] +*/}
      {/*        "=%#eFennec%#%__#%"*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <button*/}
      {/*      aria-label={"Następne zdjęcie"}*/}
      {/*      className={style.arrow}*/}
      {/*      onClick={handleNextImage}*/}
      {/*    >*/}
      {/*      {">"}*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      className={style.close}*/}
      {/*      aria-label={"Zamknij zdjęcie"}*/}
      {/*      onClick={() => setImageOpen(null)}*/}
      {/*    >*/}
      {/*      X*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default GalleryYear;
