import AboutUs from "./AboutUs/AboutUs";
import DonationAndAdvice from "../../../components/DonationAndAdvice/DonationAndAdvice";
import Map from "./Map/Map";
import Header from "../../staticComponents/Header/Header";
import { useEffect, useRef, useState } from "react";
import Articles from "./Articles/Articles";
import Links from "./Links/Links";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Blocks from "./Blocks/Blocks";

const DefaultPanel = () => {
  const advice = useRef();
  const navigate = useNavigate();
  const [dokumentyId, setDokumentyId] = useState(null);
  const [statutId, setStatutId] = useState(null);
  const data = useSelector((state) => state.websiteDates.templateSettings);
  useEffect(() => {
    if (data && data.length > 3) {
      setDokumentyId(data.find((k) => k.id == 219).value);
      setStatutId(data.find((k) => k.id == 217).value);
    }
  }, [data]);

  function desplaySubPageStatut() {
    navigate("/subPage/" + statutId);
  }

  function scrollToElement() {
    navigate("/subPage/" + dokumentyId);
  }

  return (
    <>
      <Header />

      <AboutUs />
      <Blocks />
      <DonationAndAdvice
        ariaLabel={"Link do dokumentów"}
        title="Dokumenty"
        onClickEvent={scrollToElement}
      />
      <Articles />
      <DonationAndAdvice
        ariaLabel={"Link do statutu instytucji"}
        title="Statut"
        onClickEvent={desplaySubPageStatut}
      />
      <Links />
      {/*<Advice advice={advice} />*/}
      <Map />
    </>
  );
};
export default DefaultPanel;
