import React, { useEffect, useState } from "react";

const CursorLine = ({ showLine, setShowLine }) => {
  const [linePosition, setLinePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setLinePosition({ x: event.clientX, y: event.clientY });
    };

    if (showLine) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [showLine]);

  const toggleLine = () => {
    setShowLine(!showLine);
  };

  return (
    <>
      <button tabIndex={4} onClick={toggleLine}>
        Linia wspomagająca czytanie
      </button>
      {showLine && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            pointerEvents: "none",
          }}
        >
          <hr
            style={{
              position: "absolute",
              left: 0,
              top: linePosition.y - 10,
              width: "100%",
              height: "2px",
              backgroundColor: "red",
              border: "none",
              zIndex: 9999,
              pointerEvents: "none",
            }}
          />
          <hr
            style={{
              position: "absolute",
              left: 0,
              top: linePosition.y - 13.5,
              width: "100%",
              height: "9px",
              backgroundColor: "black",
              border: "none",
              zIndex: 9997,
            }}
          />
        </div>
      )}
    </>
  );
};

export default CursorLine;
