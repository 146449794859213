import style from "./ErrorComponent.module.css";
import { useNavigate } from "react-router-dom";

const ErrorComponent = () => {
  const navigate = useNavigate();

  function displayHome() {
    navigate("/");
  }

  return (
    <div className={style.container}>
      <div className={style.containerForBackGroundAndNumber}>
        <span className={style.spanErrorNumber}>500</span>
        <div className={style.containerForBackGround}></div>
      </div>
      <span className={style.mainSpan}>
        Przepraszamy za chwilowe niedogodności. Wystąpił wewnętrzny błąd
        serwera. Nasz zespół już pracuje nad rozwiązaniem tego problemu.
        Dziękujemy za zrozumienie i cierpliwość.
      </span>
      <button onClick={displayHome}>Strona główna</button>
    </div>
  );
};
export default ErrorComponent;
