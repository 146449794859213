import style from "./VisitCounter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const VisitCounter = () => {
  const navigate = useNavigate();
  const [dataCounterOfVisits, setDataCounterOfVisits] = useState();

  function fetchData() {
    axios(process.env.REACT_APP_LINKTOAPI2 + "returnvisitscookies")
      .then((resp) => {
        setDataCounterOfVisits(resp.data.data.visits[0]);
      })
      .catch((error) => {
        //     navigate("/error");
      });
  }

  useEffect(() => {
    fetchData();
    const timer = setInterval(() => {
      fetchData();
    }, 25000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  if (!dataCounterOfVisits) {
    return <h4>Loading....</h4>;
  }
  return (
    <div className={style.container}>
      <div className={style.blockForTitleAndIcon}>
        <h4>Licznik wyświetleń:</h4>
        <FontAwesomeIcon icon={faUsers} className={style.icon} />
      </div>
      <div className={style.blockForVisits}>
        <span>Dziś:{dataCounterOfVisits.today}</span>
        <span>Wczoraj: {dataCounterOfVisits.yesterday}</span>
        <span>W tym tygodniu: {dataCounterOfVisits.week}</span>
        <span>W tym miesiącu: {dataCounterOfVisits.month}</span>
        <span>W tym roku: {dataCounterOfVisits.year}</span>
        <span>Suma: {dataCounterOfVisits.all_}</span>
      </div>
    </div>
  );
};
export default VisitCounter;
