import ArrowToTop from "../components/ArrowToTop/ArrowToTop";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BeltForContact from "./staticComponents/Header/BeltForContact/BeltForContact";
import axios from "axios";
import {
  setTemplateSettings,
  setWebsiteData,
} from "../data/reducers/websiteDataReducer";
import Footer from "./staticComponents/Footer/Footer";
import BlockForLogoAndNav from "./staticComponents/Header/BlockForLogoAndNav/BlockForLogoAndNav";
import Accessibility from "../components/Accessibility/Accessibility";
import { ScrollToTop } from "../pipes/scrollToTop";
import DailyRequest from "./DailyRequest";

const DefaultPanel = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const k = setInterval(() => {
      if (window.screenTop <= 20) {
        clearInterval(k);
      }
      window.scroll(0, 0);
    }, 5);
    setTimeout(() => {
      clearInterval(k);
    }, 1000);
    return () => {
      clearInterval(k);
    };
  }, [pathname]);

  const [belt, setBelt] = useState(true);

  function checkY() {
    if (window.pageYOffset > 15) {
      setBelt(false);
      return;
    }
    setBelt(true);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    var options = {
      icon: {
        position: {
          top: { size: 120, units: "px" },
          right: { size: 10, units: "px" },
          type: "fixed",
        },
      },
    };
    //new Accessibility();

    axios(process.env.REACT_APP_LINKTOAPI2 + "config/?fields=id,name,value")
      .then((resp) => {
        console.log(resp.data.data);

        dispatch(setWebsiteData(resp.data.data));
      })
      .catch((error) => {});
    axios(
      process.env.REACT_APP_LINKTOAPI2 +
        "template_settings/template_id/5/?fields=id,name,value",
    )
      .then((resp) => {
        dispatch(setTemplateSettings(resp.data.data));
      })
      .catch((error) => {});

    pingerFun();
    const pinger = setInterval(() => {
      pingerFun();
    }, 10000);
    return () => {
      clearInterval(pinger);
    };
  }, []);

  function pingerFun() {
    if (!localStorage.getItem("sessionId")) {
      localStorage.setItem("sessionId", 0);
    }
    if (document.hidden) {
      //   console.warn('Window is inactive');
      return;
    }
    if (document.visibilityState === "hidden") {
      // console.warn('Window is hidden');
      return;
    }
    const link = process.env.REACT_APP_LINKTOAPI + "visits";
  }

  useEffect(() => {
    document.addEventListener("scroll", checkY);
    return () => {
      document.removeEventListener("scroll", checkY);
    };
  }, []);

  return (
    <div className="flexibleBlock">
      <ScrollToTop />
      <DailyRequest />
      <Accessibility />
      {<BeltForContact />}
      <BlockForLogoAndNav belt={belt} />

      <Outlet />
      <Footer />
      <ArrowToTop />
    </div>
  );

  function generateMd5() {
    const date =
      new Date().getHours() +
      "*" +
      new Date().getMinutes() +
      "*" +
      Number(new Date().getSeconds());
    const value = `${date}`; //^${link}
    var md5 = require("md5");
    return md5(value);
  }
};
export default DefaultPanel;
