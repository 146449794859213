import style from "./AboutUs.module.css";
import Content from "./Content/Content";

const AboutUs = () => {
  // const data = useSelector(state => state.websiteDates.websiteDates.aboutUs);
  //
  //
  // if (!data) {
  //     return <h2>Loading....</h2>
  // }
  return (
    <div className={style.container} id="aboutUs">
      {/*<Content data={data} />*/}
      <Content />
      {/*<img  className={style.img}src={aboutImage} alt="aboutUs" />*/}
    </div>
  );
};
export default AboutUs;
