import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import MenuLink from "./MenuLink/MenuLink";

const NavOptions = (props) => {
  const allProjects = useSelector((state) => state.projects.projects);
  const [menu, setMenu] = useState([]);
  const isOpen = props.isOpen;

  function close() {
    if (props.setOpen) {
      props.setOpen(false);
    }
  }

  useEffect(() => {
    axios(process.env.REACT_APP_LINKTOAPI2 + "pre_menu")
      .then((resp) => {
        setMenu(resp.data.data);
      })
      .catch((error) => {});
  }, []);
  //subMenu={menu.filter(k=>k.parent_id == k.id}

  const links = menu
    .filter((k) => k.parent_id == "0")
    .sort((k1, k2) => {
      return k1.position - k2.position;
    })
    .map((k, index, array) => {
      return (
        <MenuLink
          setOpen={props.setOpen}
          isOpen={isOpen}
          subMenu={menu.filter((k2) => k2.parent_id == k.id)}
          index={index + 1}
          key={k.id}
          value={k}
        />
      );
    });

  return <>{links}</>;
};
export default NavOptions;
